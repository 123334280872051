import "Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css\",\"source\":\"Ll8xcnVhYmNyMCB7CiAgcGFkZGluZzogMCAhaW1wb3J0YW50OwogIG1hcmdpbjogMCAhaW1wb3J0YW50OwogIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDsKfQouXzFydWFiY3IyIHsKICBwb3NpdGlvbjogZml4ZWQ7CiAgd2lkdGg6IHZhcigtLV8xcnVhYmNyMSk7CiAgdG9wOiAwOwogIG91dGxpbmU6IDFweCBzb2xpZCB0cmFuc3BhcmVudDsKICBsZWZ0OiAyMDB2dzsKfQpfOjotd2Via2l0LWZ1bGwtcGFnZS1tZWRpYSwgXzpmdXR1cmUsIDpyb290IC5fMXJ1YWJjcjI6OmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHdpZHRoOiAxcHg7CiAgaGVpZ2h0OiAxcHg7CiAgbGVmdDogY2FsYygtMTAwdncgLSAxcHgpOwogIHRvcDogMDsKICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDsKfQouXzFydWFiY3IyID4gKjpmaXJzdC1jaGlsZCB7CiAgcGFkZGluZy10b3A6IDAgIWltcG9ydGFudDsKICBtYXJnaW4tdG9wOiAwICFpbXBvcnRhbnQ7Cn0KLl8xcnVhYmNyMiAuaW1hZ2Utd2lkZ2V0X19naG9zdCB7CiAgZGlzcGxheTogbm9uZTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VV0W7bIBR9z1dcRZqUaKUyTpykRH3cD+xtmqYKG5yQEvAwbtNO+/fJmNQGJ1qrKi8x93DO4d7L5fYBK/Gav7IE/kwAKl0LK7QiYLikVjzx7QRgz8VubwngJPmynfyd3J43YUJyXmrD3eZCK8uVJTCdbgMumtdaNtZxSV5aAkl1Cogzv/AsmN2fhQCsoaoutTmS7q+klv+YIQefB05SQmhpuXm/kTftjdcOnAVGBjoLJ3AtuhxGS2GRd9KCfjJqKaq55EVrBhmhdr86u42ptSFQaaEsNz3YfdKiLQN61Yr/uvEkbR6Q0ozfT7nkR67sNKRivKSNHOh6GLIvFb+fWn6y3y5ubCPBkTYujND5e0UguZ7UC0fpmT7eLLk+oXpPmX4mkLhfWp3giZpZb2g9b5G6ooWwLyQKrlwwp8XjzuhGMVRo6Y7ZdlNFjauN7zOv75k8EWsMdeWqHx5wLteHA8b13Ac5rYXaodpSxahhPeL3/Gbg/fNUkf9PEvqqmIbmhUkhKjUTdSXpCwGlFQ8KeDduBbwNVtYEzC6fpYsM0mUC6XId3lL6Xgac3UGKl5Bmy5AhfzfDCnC2gDRLQ4LCEQhVc+s6eRgKGrSHtH1oGDfIUCaaOu6x7AZG44gNKRBO26EyDF9SWnaT539a3UKH+u5AbsoYLfsa83lop/TD3V1KxJ+4svW5wL3NZeSyvJ6P0YVqU77ZAN60NVvM48s76qX9hbFyiwOIuARJA4iMHq3h7LC6irP36HyZbu4XVBazdoLD1wh2iLInPz64nLhvHWv10X90T8z5dfPvRCx+7Xp2h30bdMlQZ+D2GKXtMQaoMQDhJKq9jkAHAqsIUo0hOG4hM8ZsHOQfJdRkRXgIAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWy3LbIBTd+yuYzGTGmhpXyFHsklV3XXfXlQcBtkgIqIBsJ53+e0cgRQ8/0kbpZCe4D5177rlXmq8RWxbqYZWAXxMACm2FE1phQDKrZen43QSAvWAux2BHzBTCJiCOKpPTBQZx9ZRp5/RjcyD0YWt0qRgGZptN0S1A6QIkaQI+g3iepD74GQrF+AEDdOffLZTjBvIdV85ioLTiIbFh3EBDmChtgyJcfvd30JZmQyhfr1Eml/f3CG18el0QKtxTDckZopriakudi5WGVPdQthnKqDZyYoXaQuuIYsSw1uNnNOuUOTLV3eT3ZL5GpiQZNQmY97vChC0keWoo8a7BfuPtbaHzVc98682vNCONejFLHyP5xnniOpaVtxixzY9MX/pBZ7zI0Mv3ZKPNIw6Pkji+YNO+0FA0A/EMxH2Y2Tvm4j7XXwjEtj2z/ygQSLXUZmTCY1VfHFi0Kg7VMeehG815MFEojq+ra1oaqw1uBvHSTL5MbtInEmd8o03gk2rluHIYXF2dR+r3xyLACs2sD00JyaCE5GIJQ75rLRTEcOXODRl/Zci2/SE7uayEEk4Q2Y/DXTqOoe1z4TobDgNUHIDVUjTbxPvBikej5VdKuXLf9I6bVhI3UYg/QJsTpvfNegwnyCXfeaGhNiTraz9/Y3H5fyiuBbkYXZfwuNrJpxgsK+l0XO6PXeDQ56GzZiiRdDpYKDQCnwYfRhZFAFYybj+QaVDnqRX1YwrT+Drqzh/fQ8OteO6rUHa378dCeQz7skr3JiAj6GyAqb3lp6CpkdDG8PuCjduTtOkwKvV/0ocxdwZdMR7du5DXa+wflJ8ZFJ8KAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var snapAreaWidth = 'var(--_1d7pnk80)';
export var snapAreaXOffset = 'var(--_1d7pnk81)';
export var resizeableSnapArea = _7a468({defaultClassName:'_1d7pnk82',variantClassNames:{visible:{false:'_1d7pnk83',true:'_1d7pnk84'},highlighted:{false:'_1d7pnk85',true:'_1d7pnk86'}},defaultVariants:{},compoundVariants:[]});
export var resizeableSnapAreaContentStart = '_1d7pnk87';
export var resizeableSnapAreaContentEnd = '_1d7pnk88';
export var resizableSnapAreaContent = '_1d7pnk89';
export var snapAreaWide = '_1d7pnk8a';
export var snapAreaFull = '_1d7pnk8b';
export var resizeHandleSelectionRingOffset = 'var(--_1d7pnk8d)';
export var resizeHandle = _7a468({defaultClassName:'_1d7pnk8e',variantClassNames:{state:{idle:'_1d7pnk8f',hover:'_1d7pnk8g',active:'_1d7pnk8h'},inset:{true:'_1d7pnk8i',false:'_1d7pnk8j'},position:{left:'_1d7pnk8k',right:'_1d7pnk8l',topLeft:'_1d7pnk8m',topRight:'_1d7pnk8n',bottomLeft:'_1d7pnk8o',bottomRight:'_1d7pnk8p'}},defaultVariants:{},compoundVariants:[]});
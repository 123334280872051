"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackgroundStyleButton = void 0;
var react_1 = __importStar(require("react"));
var styles = __importStar(require("./BackgroundStyleButton.css"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var dynamic_1 = require("@vanilla-extract/dynamic");
var BackgroundStyleButton_css_1 = require("./BackgroundStyleButton.css");
var BackgroundStylePanel_1 = require("Project/BlockNew/Menu/Settings/StyleButton/BackgroundStyleButton/BackgroundStylePanel");
var BackgroundStyleButtonIcon = function (_a) {
    var _b;
    var background = _a.background;
    return (react_1.default.createElement("div", { className: styles.backgroundStyleButtonIcon, style: (0, dynamic_1.assignInlineVars)((_b = {},
            _b[BackgroundStyleButton_css_1.buttonIconBackground] = background,
            _b)) }));
};
var BackgroundStyleButton = function (_a) {
    var iconBackground = _a.iconBackground;
    var _b = __read((0, react_1.useState)(false), 2), isPopoverOpened = _b[0], setIsPopoverOpened = _b[1];
    var handleChangePopover = function (isOpen) {
        setIsPopoverOpened(isOpen);
    };
    return (react_1.default.createElement(kaleidoscope_1.Popover, { button: function (buttonProps) { return (react_1.default.createElement(kaleidoscope_1.IconButton, __assign({}, buttonProps, { isRound: true, isIconOnly: true, size: kaleidoscope_1.ButtonSize.Small, icon: react_1.default.createElement(BackgroundStyleButtonIcon, { background: iconBackground }), tooltip: {
                content: "Background",
                position: kaleidoscope_1.TooltipPosition.Top,
            } }))); }, caret: true, padding: "none", isOpen: isPopoverOpened, size: kaleidoscope_1.PopoverSize.Medium, onChange: handleChangePopover },
        react_1.default.createElement(BackgroundStylePanel_1.BackgroundStylePanel, null)));
};
exports.BackgroundStyleButton = BackgroundStyleButton;

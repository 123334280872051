"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackgroundStylePanel = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var BackgroundStylePanelTab;
(function (BackgroundStylePanelTab) {
    BackgroundStylePanelTab["Color"] = "Color";
    BackgroundStylePanelTab["Image"] = "Image";
    BackgroundStylePanelTab["Video"] = "Video";
})(BackgroundStylePanelTab || (BackgroundStylePanelTab = {}));
var BackgroundStylePanel = function (_a) {
    var _b = __read((0, react_1.useState)(BackgroundStylePanelTab.Color), 2), currentTab = _b[0], setCurrentTab = _b[1];
    return (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical" },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", justify: "center", paddingTop: "m", paddingRight: "l", paddingLeft: "l" },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", justify: "spaceBetween", relative: true },
                react_1.default.createElement(kaleidoscope_1.Text, { size: "m", strong: true, lineHeight: "relaxed" }, "Background"))),
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", padding: "l", paddingTop: "m", gap: "l" },
            react_1.default.createElement(kaleidoscope_1.SegmentedControl, { label: "Background Type", labelHidden: true, onClickHandler: function (value) {
                    setCurrentTab(value);
                    console.log("Current tab: ", value);
                }, options: [
                    {
                        label: "Color",
                        value: BackgroundStylePanelTab.Color,
                    },
                    {
                        label: "Image",
                        value: BackgroundStylePanelTab.Image,
                    },
                    {
                        label: "Video",
                        value: BackgroundStylePanelTab.Video,
                    },
                ], selectedValue: currentTab })),
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", padding: "l", paddingTop: "m", gap: "l" },
            react_1.default.createElement(kaleidoscope_1.Text, null, "To Be Built"))));
};
exports.BackgroundStylePanel = BackgroundStylePanel;

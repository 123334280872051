"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextToolbar = void 0;
var react_1 = __importStar(require("react"));
var slate_1 = require("slate");
var slate_react_1 = require("slate-react");
var mobx_react_1 = require("mobx-react");
var mobx_1 = require("mobx");
var EditorTypes_1 = require("Project/QwilrEditor/EditorTypes");
var Utils_1 = require("Project/QwilrEditor/Utils");
var TextToolbarUI_1 = require("./TextToolbarUI");
var TextToolbarUI_css_1 = require("./TextToolbarUI.css");
var WidgetManager_1 = require("Project/QwilrEditor/WidgetManager");
var ToolbarFocusTracker_1 = require("./ToolbarFocusTracker");
var styles = __importStar(require("./TextToolbar.css"));
exports.TextToolbar = (0, mobx_react_1.observer)(function (props) {
    var _a = __read((0, react_1.useState)(null), 2), lastGoodSelection = _a[0], setLastGoodSelection = _a[1];
    var _b = __read((0, react_1.useState)(false), 2), editorFocused = _b[0], setEditorFocused = _b[1];
    var _c = (0, ToolbarFocusTracker_1.useToolbarFocusTracker)(), isToolbarFocused = _c.isToolbarFocused, onFocusChange = _c.onFocusChange;
    (0, react_1.useEffect)(function () {
        var value = props.selectionStore.focussed;
        setTimeout(function () {
            setEditorFocused(value);
        }, 0);
    }, [props.selectionStore.focussed]);
    var currentSelection = (0, react_1.useMemo)(function () { return (0, mobx_1.toJS)(props.selectionStore.currentSelection); }, [
        props.selectionStore.currentSelection,
    ]);
    (0, react_1.useEffect)(function () {
        if (!currentSelection ||
            (slate_1.Range.isCollapsed(currentSelection) && !isToolbarFocused)) {
            setLastGoodSelection(null);
        }
        else {
            setLastGoodSelection(currentSelection);
        }
    }, [currentSelection, isToolbarFocused]);
    (0, react_1.useEffect)(function () {
        if (isToolbarFocused && currentSelection && slate_1.Range.isCollapsed(currentSelection) && lastGoodSelection) {
            var eventData = { eventType: "reselection", selection: lastGoodSelection, takeFocus: false };
            props.widgetManager.triggerEvent(new WidgetManager_1.WidgetEvent("toolbar", eventData));
        }
    }, [currentSelection, isToolbarFocused, props.widgetManager, lastGoodSelection]);
    return (react_1.default.createElement(ToolbarPositioner, __assign({}, props, { editorFocused: editorFocused, isToolbarFocused: isToolbarFocused, selection: lastGoodSelection, onFocusChange: onFocusChange })));
});
var ToolbarPositioner = function (props) {
    var _a = __read((0, react_1.useState)(false), 2), isToolbarVisible = _a[0], setIsToolbarVisible = _a[1];
    var mostRecentNativeSelection = (0, react_1.useRef)(null);
    var positioningDivRef = (0, react_1.useRef)(null);
    var editor = (0, slate_react_1.useSlate)();
    (0, react_1.useEffect)(function () {
        if (shouldHideToolbar(editor, props.selection, props.editorFocused, props.isToolbarFocused)) {
            hideToolbar();
        }
        else {
            setIsToolbarVisible(true);
            setToolbarPosition();
        }
    }, [props.selection, props.editorFocused, editor, props.isToolbarFocused]);
    function hideToolbar() {
        setIsToolbarVisible(false);
        if (positioningDivRef.current) {
            positioningDivRef.current.removeAttribute("style");
        }
    }
    function setToolbarPosition() {
        if (!props.dontPosition && !!props.selection) {
            try {
                var nativeRange = slate_react_1.ReactEditor.toDOMRange(editor, props.selection);
                var rangeRect = nativeRange.getBoundingClientRect();
                if (!rangeRect.left && !rangeRect.top && mostRecentNativeSelection.current) {
                    rangeRect = mostRecentNativeSelection.current.getBoundingClientRect();
                }
                else {
                    mostRecentNativeSelection.current = nativeRange;
                }
                if (!positioningDivRef.current) {
                    throw new Error("Could not find positioning element");
                }
                var positioningDiv = positioningDivRef.current;
                var editorRect = getEditorBoundingRect(props.editorElem);
                if (!editorRect) {
                    throw new Error("editorElem does not exist");
                }
                var positionLeft = rangeRect.left - editorRect.left;
                var positionTop = rangeRect.top - editorRect.top - TextToolbarUI_css_1.textToolbarHeight;
                positioningDiv.style.top = "".concat(positionTop, "px");
                positioningDiv.style.left = "".concat(positionLeft, "px");
                positioningDiv.style.opacity = "1";
            }
            catch (e) {
                console.error("Error positioning toolbar", e);
                hideToolbar();
            }
        }
    }
    return (react_1.default.createElement("div", { className: styles.positioningDiv, ref: positioningDivRef },
        react_1.default.createElement(TextToolbarUI_1.TextToolbarUI, { visible: isToolbarVisible, widgetManager: props.widgetManager, buttonConfigs: props.config, blockStore: props.blockStore, onFocusChange: props.onFocusChange })));
};
function shouldHideToolbar(editor, selection, editorFocused, isToolbarFocused) {
    if ((!selection || slate_1.Range.isCollapsed(selection) || !editorFocused) && !isToolbarFocused) {
        return true;
    }
    var changeable = ["paragraph", "heading1", "heading2", "listItem", "blockQuote"];
    var selectedBlocks = (0, Utils_1.getSelectedBlocks)(editor);
    var hasNonChangeableBlocks = selectedBlocks.some(function (entry) { return !changeable.includes(entry[0].type); });
    var hasSelectedText = selectedBlocks.some(function (entry) { return slate_1.Node.string(entry[0]).length > 0; });
    return !hasSelectedText || hasNonChangeableBlocks || aTableIsSelected(editor);
}
function aTableIsSelected(editor) {
    var e_1, _a;
    var selectedBlocks = (0, Utils_1.getSelectedBlocks)(editor);
    try {
        for (var selectedBlocks_1 = __values(selectedBlocks), selectedBlocks_1_1 = selectedBlocks_1.next(); !selectedBlocks_1_1.done; selectedBlocks_1_1 = selectedBlocks_1.next()) {
            var blockEntry = selectedBlocks_1_1.value;
            var block = blockEntry[0];
            if (block.type == "table") {
                return true;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (selectedBlocks_1_1 && !selectedBlocks_1_1.done && (_a = selectedBlocks_1.return)) _a.call(selectedBlocks_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
}
function getEditorBoundingRect(editorElem) {
    if ((0, EditorTypes_1.isHTMLElement)(editorElem)) {
        return editorElem.getBoundingClientRect();
    }
    if (!!(editorElem === null || editorElem === void 0 ? void 0 : editorElem.current)) {
        return editorElem.current.getBoundingClientRect();
    }
    return null;
}

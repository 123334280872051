import "Application/Project/ContentSidebar/ContentSidebar.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/ContentSidebar/ContentSidebar.css.ts.vanilla.css\",\"source\":\"LnhndGU1NTAgewogIHdpZHRoOiAzNjBweDsKICBhbGlnbi1zZWxmOiBmbGV4LWVuZDsKICBib3JkZXItbGVmdDogMXB4IHNvbGlkIHZhcigtLWNvbG9yLWJvcmRlcl9fMWJsN2pqMXkpOwp9Ci54Z3RlNTUxIHsKICBib3gtc2hhZG93OiB2YXIoLS1zaGFkb3ctZWxldmF0aW9uMV9fMWJsN2pqMTFiKTsKfQoueGd0ZTU1MiB7CiAgbGVmdDogLTI3OHB4Owp9Ci54Z3RlNTUzIHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgbGVmdDogMDsKfQoueGd0ZTU1NCB7CiAgaGVpZ2h0OiBjYWxjKCgoKDEwMHZoIC0gNjBweCkgLSAoNjVweCArIHZhcigtLXNwYWNpbmctbF9fMWJsN2pqMTFsKSkpIC0gdmFyKC0taG9yaXpvbnRhbC1zY3JvbGxiYXItaGVpZ2h0KSkgKyB2YXIoLS1zcGFjaW5nLWxfXzFibDdqajExbCkpOwp9\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var foldersWidth = '278px';
export var sidebarWidth = '360px';
export var sidebarZIndex = 501;
export var sidebarHeader = 'xgte550';
export var sidebarInner = _7a468({defaultClassName:'xgte551',variantClassNames:{withFoldersOffset:{true:'xgte552',false:'xgte553'}},defaultVariants:{},compoundVariants:[]});
export var editorNavigationHeight = '60px';
export var sidebarHeaderComponent = '65px';
export var sidebarHeaderPadding = 'var(--spacing-l__1bl7jj11l)';
export var sidebarViewContainer = 'xgte554';
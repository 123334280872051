import "Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css\",\"source\":\"Ll8xcnVhYmNyMCB7CiAgcGFkZGluZzogMCAhaW1wb3J0YW50OwogIG1hcmdpbjogMCAhaW1wb3J0YW50OwogIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDsKfQouXzFydWFiY3IyIHsKICBwb3NpdGlvbjogZml4ZWQ7CiAgd2lkdGg6IHZhcigtLV8xcnVhYmNyMSk7CiAgdG9wOiAwOwogIG91dGxpbmU6IDFweCBzb2xpZCB0cmFuc3BhcmVudDsKICBsZWZ0OiAyMDB2dzsKfQpfOjotd2Via2l0LWZ1bGwtcGFnZS1tZWRpYSwgXzpmdXR1cmUsIDpyb290IC5fMXJ1YWJjcjI6OmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHdpZHRoOiAxcHg7CiAgaGVpZ2h0OiAxcHg7CiAgbGVmdDogY2FsYygtMTAwdncgLSAxcHgpOwogIHRvcDogMDsKICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDsKfQouXzFydWFiY3IyID4gKjpmaXJzdC1jaGlsZCB7CiAgcGFkZGluZy10b3A6IDAgIWltcG9ydGFudDsKICBtYXJnaW4tdG9wOiAwICFpbXBvcnRhbnQ7Cn0KLl8xcnVhYmNyMiAuaW1hZ2Utd2lkZ2V0X19naG9zdCB7CiAgZGlzcGxheTogbm9uZTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VV0W7bIBR9z1dcRZqUaKUyTpykRH3cD+xtmqYKG5yQEvAwbtNO+/fJmNQGJ1qrKi8x93DO4d7L5fYBK/Gav7IE/kwAKl0LK7QiYLikVjzx7QRgz8VubwngJPmynfyd3J43YUJyXmrD3eZCK8uVJTCdbgMumtdaNtZxSV5aAkl1Cogzv/AsmN2fhQCsoaoutTmS7q+klv+YIQefB05SQmhpuXm/kTftjdcOnAVGBjoLJ3AtuhxGS2GRd9KCfjJqKaq55EVrBhmhdr86u42ptSFQaaEsNz3YfdKiLQN61Yr/uvEkbR6Q0ozfT7nkR67sNKRivKSNHOh6GLIvFb+fWn6y3y5ubCPBkTYujND5e0UguZ7UC0fpmT7eLLk+oXpPmX4mkLhfWp3giZpZb2g9b5G6ooWwLyQKrlwwp8XjzuhGMVRo6Y7ZdlNFjauN7zOv75k8EWsMdeWqHx5wLteHA8b13Ac5rYXaodpSxahhPeL3/Gbg/fNUkf9PEvqqmIbmhUkhKjUTdSXpCwGlFQ8KeDduBbwNVtYEzC6fpYsM0mUC6XId3lL6Xgac3UGKl5Bmy5AhfzfDCnC2gDRLQ4LCEQhVc+s6eRgKGrSHtH1oGDfIUCaaOu6x7AZG44gNKRBO26EyDF9SWnaT539a3UKH+u5AbsoYLfsa83lop/TD3V1KxJ+4svW5wL3NZeSyvJ6P0YVqU77ZAN60NVvM48s76qX9hbFyiwOIuARJA4iMHq3h7LC6irP36HyZbu4XVBazdoLD1wh2iLInPz64nLhvHWv10X90T8z5dfPvRCx+7Xp2h30bdMlQZ+D2GKXtMQaoMQDhJKq9jkAHAqsIUo0hOG4hM8ZsHOQfJdRkRXgIAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var ringDataAttribute = 'data-selection-ring';
export var interactiveElementSelector = '[data-interactive-zone]';
export var textElementSelector = '[data-element-type="textElement"]';
export var childSelector = '[data-slate-node="element"]';
export var selectionRingWidth = 2;
export var wrapper = _7a468({defaultClassName:'_1nizbzd0',variantClassNames:{extraSpaceBeforeFirstWidgetInBlock:{true:'_1nizbzd1'},extraSpaceAfterLastWidgetInBlock:{true:'_1nizbzd2'},widgetWidth:{'full-width':'_1nizbzd3','fit-widget':'_1nizbzd4'}},defaultVariants:{},compoundVariants:[]});
export var ringBorderRadius = 'var(--_1nizbzd5)';
export var ring = _7a468({defaultClassName:'_1nizbzd8',variantClassNames:{mouseOverWidget:{true:'_1nizbzd9'},mouseOverSelectableRegion:{true:'_1nizbzda'},selected:{true:'_1nizbzdb'},mode:{hugging:'_1nizbzdc',outside:'_1nizbzdd'},nodeType:{'void':'_1nizbzde',textElement:'_1nizbzdf',layoutElement:'_1nizbzdg'}},defaultVariants:{},compoundVariants:[[{mouseOverSelectableRegion:true,nodeType:'textElement'},'_1nizbzdh'],[{selected:true,nodeType:'textElement'},'_1nizbzdi']]});
export var dragHandle = _7a468({defaultClassName:'_1nizbzdl',variantClassNames:{alignment:{widget:'_1nizbzdm','selection-ring':'_1nizbzdn'},mode:{hugging:'_1nizbzdo',outside:'_1nizbzdp'},nodeType:{'void':'_1nizbzdq',textElement:'_1nizbzdr',layoutElement:'_1nizbzds'}},defaultVariants:{},compoundVariants:[]});
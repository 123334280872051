"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var tokens_1 = require("@qwilr/kaleidoscope/tokens");
var CommentWIthMarker_1 = require("Project/QwilrEditor/Comments/CommentWIthMarker");
var EditorTypes_1 = require("./EditorTypes");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var ColorProvider_1 = require("@CommonFrontend/StyleServiceV2/ColorProvider");
var Leaf = function (props) {
    var e_1, _a;
    var _b = props.properties, _c = _b.leaf, marks = _c.marks, showSelectionHighlight = _c.showSelectionHighlight, attributes = _b.attributes;
    var colorProvider = (0, react_1.useContext)(ColorProvider_1.ColorProviderContext);
    var children = props.properties.children;
    var styles = {};
    try {
        for (var _d = __values(marks), _e = _d.next(); !_e.done; _e = _d.next()) {
            var mark = _e.value;
            switch (mark.type) {
                case EditorTypes_1.MarkType.Bold:
                    children = react_1.default.createElement("b", null, children);
                    break;
                case EditorTypes_1.MarkType.Italic:
                    children = react_1.default.createElement("i", null, children);
                    break;
                case EditorTypes_1.MarkType.Strikethrough:
                    children = react_1.default.createElement("s", null, children);
                    break;
                case EditorTypes_1.MarkType.Comment:
                    children = (react_1.default.createElement(CommentWIthMarker_1.CommentWithMarker, __assign({ mark: mark }, props), children));
                    break;
                case EditorTypes_1.MarkType.Color:
                    styles = __assign(__assign({}, styles), { color: colorProvider.decorateColor((0, GetBlockStyle_1.convertColorObjToColor)(mark.data.color)).value });
                    console.debug("RenderLeaf::MarkType.Color", { styles: styles });
                    break;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (showSelectionHighlight) {
        styles.backgroundColor = tokens_1.palette.orange200;
    }
    return (react_1.default.createElement("span", __assign({ style: __assign({}, styles) }, attributes), children));
};
exports.default = Leaf;
